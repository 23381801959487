// globalMixins.js
// export const globalMixins = {
//     methods: {
//       formatMoney(value, currency = 'USD') {
//         return new Intl.NumberFormat('es-ES', { style: 'currency', currency: currency }).format(value);
//       }
//     }
// };

import { MESSAGES } from "./const";

// Funcion para mostrar formato de moneda
export const globalMixins = {
  methods: {
    formatMoney(value, currency = 'HNL') {
      let formattedValue = Number(value).toFixed(2); // Asegura que siempre tenga dos decimales
      

      switch (currency) {
        case 'HNL':
          return `L${formattedValue}`; // Formato para Lempira
        case 'USD':
          return `$${formattedValue}`; // Formato para Dólar
        default:
          return `${currency}${formattedValue}`; // Formato por defecto para otras monedas
      }
    }
  }
};

// Funcion para mostrar descripcion segun metodo de entrega
export function getDeliveryMessage(deliveryMethod) {
  switch (deliveryMethod) {
      case 'Delivery':
          return MESSAGES.DESCRIPTION_DELIVERY;
      case 'Pickup':
          return MESSAGES.DESCRIPTION_PICKUP;
      default:
          return '';
  }
}

// Función para filtrar tipos de envío únicos basados en el nombre
export function uniqueShippingTypes(shippingTypes) {
  const seen = new Set();
  return shippingTypes.filter((shippingOption) => {
    const duplicate = seen.has(shippingOption.name);
    seen.add(shippingOption.name);
    return !duplicate;
  });
}


// Función para obtener el código ISO procesado
export function getAdministrativeArea(department) {
  const iso = department.iso;
  if (iso.includes('-')) {
    return iso.split('-')[1];
  }
  return iso;
}

// Funcion para obtener el nombre del comercio formateado segun longitud de caracteres permitidos por Visa y MasterCard
export function formatMerchantName(name) {
  const maxLength = 22; // Máximo permitido por Visa y MasterCard
  const minLength = 3;  // Mínimo recomendado

  if (name.length > maxLength) {
      return name.substring(0, maxLength - 1) + "."; // Trunca y añade un punto si es necesario
  } else if (name.length < minLength) {
      return name.padEnd(minLength, "."); // Rellena con puntos si es demasiado corto
  }
  return name;
}